
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "animated_checkboxes";
@import "bootsrap_datepicker";

label.error{
    color: red;
}
.marg-top{
    margin-top: 20px;
}
.marg-btm{
    margin-bottom: 20px;
}
.marg-left{
    margin-left: 20px;
}
.marg-right{
    margin-right: 20px;
}
.pointer{
    cursor: pointer;
}
.custom-hover:hover{
    background-color: darken($table-bg-hover, 5%);
}

.cover-container {
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    .cover {

        padding: 40px 20px;
        .cover-heading{
            color: $brand-danger;
            font-weight:bold;
            padding-bottom: 20px;
        }
        .lead{
            color: white;
        }
        .btn-lg {
            padding: 10px 20px;
            font-weight: bold;
        }
    }
}

.nav-events{
    width: 100%;
    padding-bottom: 1em;

    ul {
        margin: 0;
        padding: 0;
        @media (min-width: $grid-float-breakpoint) {
            display: flex;
        }
    }

    .btn{
        padding: 10px 30px;
    }
    .btn-default:active, .btn-default.active{
        background-color: $brand-danger;
        border-color: darken($brand-danger, 5%);
        color: white;
    }
}

.nav-registration{
    margin-top: 30px;

    li.active > a, li.active > a:hover, li.active > a:focus{
        color: $text-color;
        background-color: transparent;
        border-color: $brand-danger;
    }
    li > a{
        color: $text-color;
    }

    li > a:hover, li > a:focus {
        background-color: transparent;
    }
}

.event-container{
    background-color: white;
    padding: 2em;

    .event{
        @media (min-width: $grid-float-breakpoint) {
            display: flex;

            .event-cart {
                flex: 2;
            }
            .event-description{
                flex:1;
                padding: 0px;
            }
        }

        .event-description{
            padding: 30px 20px 0px 0px;
            vertical-align: middle;
            letter-spacing: 1px;
        }

        .form-group-ticket{
            text-align: center;
            margin-bottom: 30px;
            display: flex;
            align-items: center;

            @media (max-width: $screen-md) {
                flex-direction: column;
            }

            label{
                margin-left: 15px;
                font-size: 15px;
            }
        }
    }
}

.panel-auth, .panel-summary, .panel-main{
    margin-top: 30px;
}

.panel-main{
    .lead{ margin: 20px 0px;}
}

.nav.navbar-center{
    text-align: center;
    width: 100%;
    .dropdown{
        float: none !important;
        display: inline-block;
        .dropdown-menu{
            width: 100%;
            text-align: center;
        }
    }
}

.payments-container{
    h3.page-header{
        margin-top: 0px;
    }

    .ac-custom{
        ul{
            display: flex;
        }
        li{
            padding: 1em 0;
        }
        label{
            font-size: $font-size-h3;
            font-weight: normal;
            padding: 0 0 0 60px;
        }

        input[type="checkbox"], input[type="radio"], label::before{
            width: 40px;
            height: 40px;
            top: 50%;
            left: 3px;
            margin-top: -20px;
            position: absolute;
            cursor: pointer;
        }

        label::before{
            border: 3px solid lighten( $text-color, 15%);
        }
    }
}
.invoice-request{
    margin-top: 16px;
}
.invoice-container{
    .invoice-request-block{
        .btn-default:active, .btn-default.active{
            background-color: $brand-danger;
            border-color: darken($brand-danger, 5%);
            color: white;
        }
    }
    .invoice{
        display: none;
    }

    .page-header{
        margin-top: 0px;
    }
}

.popover-info-button{
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-width-limited{
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-danger, .form-control.bg-danger{
    background-color: #f2dede;
}

table td.align-vertical{
    vertical-align: middle !important;
}